import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../utils/style'

export const At = () => (
  <Svg version="1.1" width="24" height="28" viewBox="0 0 24 28">
    <path d="M15.188 12.109c0-2.25-1.172-3.594-3.141-3.594-2.594 0-5.375 2.578-5.375 6.75 0 2.328 1.156 3.656 3.187 3.656 3.141 0 5.328-3.594 5.328-6.813zM24 14c0 4.859-3.469 6.687-6.438 6.781-0.203 0-0.281 0.016-0.5 0.016-0.969 0-1.734-0.281-2.219-0.828-0.297-0.344-0.469-0.781-0.516-1.297-0.969 1.219-2.656 2.406-4.766 2.406-3.359 0-5.281-2.078-5.281-5.703 0-4.984 3.453-9.031 7.672-9.031 1.828 0 3.297 0.781 4.078 2.109l0.031-0.297 0.172-0.875c0.016-0.125 0.125-0.281 0.234-0.281h1.844c0.078 0 0.156 0.109 0.203 0.172 0.047 0.047 0.063 0.172 0.047 0.25l-1.875 9.594c-0.063 0.297-0.078 0.531-0.078 0.75 0 0.844 0.25 1.016 0.891 1.016 1.062-0.031 4.5-0.469 4.5-4.781 0-6.078-3.922-10-10-10-5.516 0-10 4.484-10 10s4.484 10 10 10c2.297 0 4.547-0.797 6.328-2.25 0.219-0.187 0.531-0.156 0.703 0.063l0.641 0.766c0.078 0.109 0.125 0.234 0.109 0.375-0.016 0.125-0.078 0.25-0.187 0.344-2.125 1.734-4.828 2.703-7.594 2.703-6.609 0-12-5.391-12-12s5.391-12 12-12c7.172 0 12 4.828 12 12z" />
  </Svg>
)

export const Insta = () => (
  <Svg version="1.1" width="24" height="28" viewBox="0 0 24 28">
    <path d="M16 14c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM18.156 14c0 3.406-2.75 6.156-6.156 6.156s-6.156-2.75-6.156-6.156 2.75-6.156 6.156-6.156 6.156 2.75 6.156 6.156zM19.844 7.594c0 0.797-0.641 1.437-1.437 1.437s-1.437-0.641-1.437-1.437 0.641-1.437 1.437-1.437 1.437 0.641 1.437 1.437zM12 4.156c-1.75 0-5.5-0.141-7.078 0.484-0.547 0.219-0.953 0.484-1.375 0.906s-0.688 0.828-0.906 1.375c-0.625 1.578-0.484 5.328-0.484 7.078s-0.141 5.5 0.484 7.078c0.219 0.547 0.484 0.953 0.906 1.375s0.828 0.688 1.375 0.906c1.578 0.625 5.328 0.484 7.078 0.484s5.5 0.141 7.078-0.484c0.547-0.219 0.953-0.484 1.375-0.906s0.688-0.828 0.906-1.375c0.625-1.578 0.484-5.328 0.484-7.078s0.141-5.5-0.484-7.078c-0.219-0.547-0.484-0.953-0.906-1.375s-0.828-0.688-1.375-0.906c-1.578-0.625-5.328-0.484-7.078-0.484zM24 14c0 1.656 0.016 3.297-0.078 4.953-0.094 1.922-0.531 3.625-1.937 5.031s-3.109 1.844-5.031 1.937c-1.656 0.094-3.297 0.078-4.953 0.078s-3.297 0.016-4.953-0.078c-1.922-0.094-3.625-0.531-5.031-1.937s-1.844-3.109-1.937-5.031c-0.094-1.656-0.078-3.297-0.078-4.953s-0.016-3.297 0.078-4.953c0.094-1.922 0.531-3.625 1.937-5.031s3.109-1.844 5.031-1.937c1.656-0.094 3.297-0.078 4.953-0.078s3.297-0.016 4.953 0.078c1.922 0.094 3.625 0.531 5.031 1.937s1.844 3.109 1.937 5.031c0.094 1.656 0.078 3.297 0.078 4.953z" />
  </Svg>
)

const Svg = styled.svg`
  height: 18px;
  width: 18px;
  margin-right: 5px;
`
