/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Header = ({ className, children, crumbs }) => {
  let bread
  if (crumbs) {
    bread = crumbs.map((crumb, i) => {
      return (
        <span key={crumb.slug} sx={{ whiteSpace: 'nowrap', ml: 2 }}>
          {i < crumbs.length - 1 ? (
            <Styled.a
              as={Link}
              sx={{ variant: 'links.primary' }}
              to={crumb.slug}
            >
              {crumb.title}
            </Styled.a>
          ) : (
            <span> {crumb.title}</span>
          )}
          {i < crumbs.length - 1 && <span> / </span>}
        </span>
      )
    })
  }

  return (
    <>
      <header
        sx={{
          height: 'header',
          mx: 1,
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          background: 'white',
          top: 10,
          left: 10,
          right: 10,
          overflow: 'hidden',
          zIndex: 3,
        }}
        className={className}
      >
        {bread ? bread : <span>kristo jorgenson</span>}
      </header>
      <div sx={{ height: 'header' }} />
    </>
  )
}

export default Header
