/** @jsx jsx */
import { jsx, ThemeProvider } from 'theme-ui'
import React from 'react'

const Callout = ({ children, emoji, color = 'highlight' }) => (
  <div sx={{ bg: color, p: 3, display: 'flex' }}>
    <span>{emoji && emoji}</span>
    <span sx={{ ml: 1 }}>{children}</span>
  </div>
)

const TableOfContents = () => <div>table of contents</div>

const Hero = ({ ...props }) => {
  return <div>image</div>
}

const Title = ({ children }) => (
  <>
    <h1 sx={{ fontWeight: 700, fontSize: [32, 40], lineHeight: 1.1, mb: 3 }}>
      {children}
    </h1>
    <div sx={{ bg: 'highlight', height: '1px' }} />
  </>
)

const Caption = ({ children }) => (
  <div sx={{ mb: '1rem', mt: '-10px', color: 'gray', fontSize: 1 }}>
    {children}
  </div>
)

const components = {
  Callout,
  TableOfContents,
  Hero,
  Title,
  Caption,
}

export default components
