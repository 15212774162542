/** @jsx jsx */
import { jsx, Styled, components } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import SEO from '../components/SEO'

import { query, Reset } from '../utils/style'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { MDXProvider } from '@mdx-js/react'
import MdxComponents from '../components/mdxComponents'

const Layout = ({ children, crumbs, background = 'normal' }) => {
  return (
    <MDXProvider components={MdxComponents}>
      <Styled.root
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 20px)',
          width: 'calc(100vw - 20px)',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'accent',
        }}
      >
        <Reset />
        <SEO />
        <Header crumbs={crumbs} />
        {children}
        <Footer />
      </Styled.root>
    </MDXProvider>
  )
}

export default Layout
