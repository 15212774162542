/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { At, Insta } from './icons.js'
import styles from '../../utils/style'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export default ({ className }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          social {
            name
            url
          }
        }
      }
    }
  `)
  return (
    <footer
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        p: 3,
      }}
      className={className}
    >
      {data.site.siteMetadata.social.map((platform, i, arr) => (
        <React.Fragment key={platform.url}>
          <Styled.a
            href={platform.url}
            target="_blank"
            as={OutboundLink}
            rel="noopener"
            sx={{ variant: 'links.primary' }}
          >
            {platform.name}
          </Styled.a>
          {arr.length - 1 !== i && <span sx={{ mx: 2 }}>/</span>}
        </React.Fragment>
      ))}
    </footer>
  )
}

const Wrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  z-index: 3;
`
