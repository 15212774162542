import React from 'react'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/core'
import { Link as GatLink } from 'gatsby'
import emotionNormalize from 'emotion-normalize'

export const query = size => (...args) => css`
  @media (min-width: ${size / 16}em) {
    ${css(...args)}
  }
`
export const Reset = () => (
  <Global
    styles={css`
      ${emotionNormalize}
      html, body {
        padding: 0;
        margin: 0;
      }
    `}
  />
)
